@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz,wght@0,6..96,400..900;1,6..96,400..900&display=swap');

* {
  box-sizing: border-box;
}

.title,
h1,
h2,
h3,
h4,
h5 {
  font-family: "Bodoni Moda", serif;
  font-weight: 600;
}

body {
  font-family: "Bodoni Moda", serif;
  font-size: 18px;
  line-height: 1.3;
  color: #000;
  font-weight: 400;
}

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 1690px;
  padding: 0 20px;
  width: 90%;
}

.small-container {
  max-width: 1150px;
  width: 100%;
  padding: 0 20px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.fs-20 {
  font-size: 20px;
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

.hero-banner-wrap {
  background-color: #2B2B2B;
  padding: 50px;
}

.light-text {
  color: #fff;
}

.banner-top-title {
  font-size: 22px;
}

.banner-title {
  font-size: 70px;
}

.banner-text {
  font-size: 28px;
}

.step-sub-title {
  padding-right: 100px;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
}

.main-step-list li:not(:last-child) {
  padding-right: 55px;
}

.main-step-list li:not(:last-child)::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #2B2B2B;
  right: 0px;
  z-index: 0;
  top: 50%;
  margin-top: -1px;
}

.main-step-list li span {
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: 2px solid #2B2B2B;
  border-radius: 100%;
  color: #2B2B2B;
  font-size: 12px;
  line-height: 120%;
  font-weight: 600;
  z-index: 1;
}

.main-step-list li.active span {
  background-color: #2B2B2B;
  color: #fff;
}

.process-step-content {
  padding-right: 22%;
}

.process-step-detail>img {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
}

.overlay-step-image {
  position: absolute;
  right: 10%;
  top: 30%;
  width: 46%;
}

.process-step-day {
  position: absolute;
  right: 3%;
  top: 19%;
  background-color: #2B2B2B;
  color: #FCF9F0;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  font-size: 18px;
  line-height: 100%;
}

.step-day-main {
  transform: rotate(-45deg);
}

.step-day-main .number {
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
}

.process-step-text p {
  width: 43%;
  margin-top: 20px;
}

.ring-background img {
  height: 100vh;
  object-fit: cover;
  object-position: bottom;
  width: 100%;
  min-height: 600px;
}

.thank-you-image img {
  height: 400px;
  object-fit: cover;
  width: 100%;
}

.small-text {
  font-size: 14px;
}

.ring-section-title {
  font-size: 26px;
}

.site-btn {
  background-color: #24272a;
  color: #fff;
  border-color: #24272a;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border-radius: 4px;
  height: 60px;
  padding: 5px;
  line-height: 50px;
  min-width: 300px;
  border: 2px solid transparent;
  margin: 0;
  display: inline-block;
  cursor: pointer;
  outline: none;
  vertical-align: middle;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  box-shadow: none !important;
}

.site-btn:hover {
  color: #24272a;
  background-color: #fff;
  border-color: #24272a;
}

.site-btn-light {
  background-color: #fff;
  color: #24272a;
  border-color: #24272a;
}

.site-btn-light:hover {
  background-color: #24272a;
  color: #fff;
  border-color: #24272a;
}

.main-page-title {
  font-size: 40px;
}

.label-title {
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.01em;
  font-weight: 400;
  white-space: nowrap;
}

.red-font {
  color: red;
}

.form-label {
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  margin-bottom: 4px;
}

.upload-file input {
  position: absolute;
  top: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
}

.plus-icon {
  background-color: #f8f8f8;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.picture-label {
  border: 1px dashed #C4C4C4;
  border-radius: 5px;
  padding: 25px;
  cursor: pointer;
}

.upload-label-text {
  font-size: 14px;
  line-height: 20px;
  color: #656565;
  font-weight: 500;
}

.upload-close-img {
  position: absolute;
  right: 13px;
  top: 10px;
  display: block;
}

.view-text {
  font-size: 14px;
  line-height: 17px;
  color: #656565;
  font-weight: 500;
}

.upload-view-thumb img {
  height: 190px;
  object-fit: cover;
}

.border-line {
  border-bottom: 1px solid #2B2B2B;
}

.border-line::after {
  content: 'OR';
  color: #656565;
  position: absolute;
  font-size: 15px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 0 10px;
}

.form-control,
.form-select {
  border: 1px solid #B3B3B3 !important;
  border-radius: 4px;
  line-height: 39px;
  background-color: #fff;
  box-shadow: none !important;
}

textarea.form-control {
  line-height: 1.5;
}

.label-with {
  width: 140px;
}

.field-content {
  flex: 1;
}

.white-gold a,
.platinum a {
  background-color: #e9e9e9;
}

.yellow-gold a {
  background-color: #e5ce83;
}

.rose-gold a {
  background-color: #e7ba9a;
}

.metals a {
  display: block;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  font-size: 14px;
  margin: 0 auto;
  cursor: pointer;
}

.metals a img {
  padding: 4px;
  border: 2px solid transparent;
  border-radius: 50%;
}

.metals a.active img {
  border-color: #2B2B2B;
}

.metal-color-slider {
  column-gap: 40px;
}

.metals-type a {
  color: #2B2B2B;
  text-decoration: none;
  cursor: pointer;
}

.metals-type a img {
  width: 20px;
}

.metals-type a span {
  font-size: 14px;
  padding-top: 4px;
  transition: 0.3s all;
}

.metals-type a span:hover {
  font-weight: 600;
}

.metal-type {
  column-gap: 30px;
}

.main-diamond-image {
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.01em;
  font-weight: 600;
}

.shape-icon {
  height: 97px;
  width: 97px;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

.slick-prev:hover,
.slick-prev:focus {
  background: url('./images/left-arrow.svg') center/contain no-repeat fixed !important;
}

.slick-next:hover,
.slick-next:focus {
  background: url('./images/right-arrow.svg') center/contain no-repeat fixed !important;
}

.slick-disabled {
  opacity: 0.5;
}

.slick-arrow::before {
  display: none;
}

.slick-arrow {
  width: 16px;
  height: 18px;
  z-index: 999;
}

.slick-prev {
  left: -10px;
  background-image: url('./images/left-arrow.svg') !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.slick-next {
  right: -12px;
  background-image: url('./images/right-arrow.svg') !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.shape-item {
  border: 2px solid transparent;
  padding: 11px 5px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s all;
}

.shape-item img {
  display: block;
  height: 60px;
  width: 60px;
  object-fit: contain;
  margin: 0 auto;
}

.shape-item.active,
.shape-item:hover {
  border-color: #2B2B2B;
}

.carat-item {
  border: 2px solid transparent;
  padding: 12px 5px;
  border-radius: 4px;
  font-size: 18px;
  line-height: 19px;
  font-weight: 500;
  color: #505050;
  text-decoration: none;
  transition: 0.3s all;
  text-align: center;
  cursor: pointer;
}

.carat-item:hover,
.active.carat-item {
  border-color: #2B2B2B;
  color: #2B2B2B;
  font-weight: 700;
}

.back-btn {
  font-size: 13px;
  font-weight: 600;
  padding: 10px;
  height: initial;
  line-height: 16px;
  display: inline-block;
  width: auto;
  background-color: transparent;
  color: #2B2B2B;
  border: 2px solid #2B2B2B;
  border-radius: 4px;
}

.back-btn:hover {
  background-color: #2B2B2B;
  color: #fff;
}

.btn-small {
  font-size: 13px;
  font-weight: 500;
  padding: 10px;
  height: initial;
  line-height: 16px;
  display: inline-block;
  width: auto;
  background-color: #2B2B2B;
  color: #fff;
  border: 2px solid #2B2B2B;
  border-radius: 4px;
}

.btn-small:hover {
  border-color: #2B2B2B;
  background-color: transparent;
  color: #2B2B2B;
}

.edit-text {
  font-size: 14px;
  text-decoration: underline;
  color: #2B2B2B !important;
  cursor: pointer;
  font-weight: 400;
}

.image-edit-text {
  position: absolute;
  left: calc(100% + 4px);
  top: 0;
}

.form-field-style {
  background-color: #F5F0DD;
  border: 1px solid #B3B3B3;
  border-radius: 4px;
  padding: 12px 15px;
  line-height: 1.2;
  font-size: 14px;
}

.url-link-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textarea-style {
  min-height: 130px;
}

.final-label {
  white-space: nowrap;
  width: 144px;
}

.final-content {
  flex: 1;
}

.shape-item-show img {
  width: 110px;
}

.field-style-2 {
  border: 1px solid #2B2B2B;
  font-size: 14px;
  padding: 12px 15px;
  color: #2B2B2B;
  font-weight: 500;
  border-radius: 4px;
}

.checkbox-style label {
  font-size: 12px;
}

.checkbox-style .form-check-input:checked {
  background-color: #2B2B2B;
  border-color: #2B2B2B;
  box-shadow: none !important;
  outline: none !important;
}

.checkbox-style .form-check-input:focus {
  border-color: #2B2B2B;
}

.checkbox-style .form-check-input {
  box-shadow: none !important;
  outline: none !important;
}

.ring-section::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.breadcrumb a {
  color: #000;
  text-decoration: none;
  margin-right: 5px;
  font-weight: 600;
}

input[type="file"].button-style {
  display: none;
}

.add-photo-btn {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  line-height: 48px;
}

.request-view-title {
  font-size: 40px;
  font-weight: 600;
}

.ring-image-view {
  border-radius: 6px;
}

.ring-image-list {
  margin: 0 -5px;
}

.ring-image-view a {
  width: 33.33%;
  display: block;
  padding: 0 5px;
  margin-bottom: 10px;
}

.ring-image-view img {
  height: 150px;
  width: 100%;
  display: block;
  object-fit: cover;
}

.app-sub-title {
  font-weight: 600;
}

.heading-wrap {
  justify-content: space-between;
}

.review-list {
  list-style-type: disc;
  padding-left: 20px;
}

.review-list li {
  margin-bottom: 12px;
}

.review-list li:last-child {
  margin-bottom: 0;
}

.ring-section-content-home {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  width: 100%;
}

.home-title {
  font-size: 35px;
  line-height: 1.12;
  font-style: italic;
}

.home-dark-btn {
  font-size: 24px;
  font-weight: 700;
  padding: 12px 25px;
  line-height: 1;
  color: #ffffff;
  background-color: #000;
  outline: none;
  border: 2px solid #000;
  transition: 0.3s all;
}

.home-dark-btn:hover {
  background-color: #fff;
  color: #000;
}

.home-light-btn {
  font-size: 24px;
  font-weight: 700;
  padding: 12px 25px;
  line-height: 1;
  color: #000;
  background-color: transparent;
  outline: none;
  border: 1px solid #000;
  transition: 0.3s all;
}

.home-light-btn:hover {
  background-color: #000;
  color: #fff;
}

.main-home p {
  font-size: 20px;
}

.home-left-col {
  padding-right: 50px !important;
  border-right: 1px solid #000;
}

.home-right-col {
  padding-left: 50px !important;
}

.day-text {
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #000;
  padding-right: 15px;
  font-style: italic;
  text-transform: uppercase;
}

.home-left-col::after {
  content: '';
  position: absolute;
  right: -17px;
  top: 50%;
  transform: translateY(-50%);
  height: 34px;
  width: 34px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #000;
  font-size: 24px;
  text-align: center;
}

.home-left-col-1::after {
  content: '1';
}

.home-left-col-2::after {
  content: '2';
}

.home-left-col-3::after {
  content: '3';
}

.home-footer {
  padding: 40px;
  background-color: #F3F3F3;
}

.approve-btn:disabled {
  cursor: no-drop;
  opacity: 0.8;
  background-color: #2B2B2B !important;
  color: #ffffff !important;
}

.approve-btn:disabled:hover {
  opacity: 0.7;
}

.home-container {
  max-width: 1500px;
}

.home-banner img {
  height: 600px;
  object-fit: cover;
}

.home-small-container {
  max-width: 720px;
}

.home-main-title {
  font-size: 50px;
  font-style: italic;
}

.bottom-banner-content-inner {
  max-width: 400px;
}

.bottom-banner-content {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
}

.video-control {
  height: 110px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 5px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 9;
  opacity: 0.8;
}

.video-control svg {
  height: 90px;
  width: 90px;
}

@media (max-width: 1600px) {
  .overlay-step-image {
    right: 8%;
    width: 50%;
    top: 35%;
  }

  .process-step-day {
    right: -1%;
    top: 24%;
    font-size: 14px;
    width: 70px;
    height: 70px;
  }

  .step-day-main .number {
    font-size: 20px;
    line-height: 24px;
  }

  .process-step-content {
    padding-right: 8%;
  }

  .process-step-text p {
    margin-top: 10px;
    width: 42%;
  }

  .process-step-text p,
  .process-step-text ul li {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }

  .step-sub-title {
    padding-right: 50px;
  }

  .home-banner img {
    height: 500px;
  }

  .home-main-title {
    font-size: 46px;
  }

}

@media (max-width: 1366px) {
  .container {
    width: 99%;
  }

  .process-step-content {
    padding-right: 12%;
  }

  .overlay-step-image {
    right: 12%;
  }

  .process-step-day {
    width: 50px;
    height: 50px;
  }

  .step-day-main .number {
    font-size: 16px;
    line-height: 20px;
  }

  .banner-title {
    font-size: 55px;
  }

  .banner-text {
    font-size: 24px;
  }

  .main-home p {
    font-size: 20px;
  }

  .home-dark-btn,
  .home-light-btn {
    font-size: 20px;
  }

  .home-left-col {
    padding-right: 30px !important;
  }

  .home-right-col {
    padding-left: 30px !important;
  }

  .home-banner img {
    height: 400px;
  }

}

@media (max-width: 1199px) {
  body {
    font-size: 16px;
  }

  .process-step-day {
    right: 4%;
  }

  .step-sub-title {
    padding-right: 15px;
    font-size: 16px;
  }

  .main-step-list li:not(:last-child) {
    padding-right: 30px;
  }

  .main-page-title {
    font-size: 38px;
  }

  .home-main-title {
    font-size: 40px;
  }

  .main-home p {
    font-size: 18px;
  }

  .home-video {
    height: 400px;
    object-fit: cover;
  }
}

@media (max-width: 991px) {
  .banner-title {
    font-size: 48px;
  }

  .banner-text {
    font-size: 22px;
  }

  .shape-item img {
    height: 40px;
    width: 40px;
  }

  .ring-image-view img {
    height: 150px;
  }

  .main-home p {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .main-page-title {
    font-size: 32px;
  }

  .hero-banner-wrap {
    padding: 40px 20px;
  }

  .overlay-step-image {
    right: 6%;
    top: 28%;
  }

  .process-step-day {
    right: 2.5%;
  }

  .banner-title {
    font-size: 32px;
  }

  .banner-text {
    font-size: 18px;
  }

  .label-with {
    width: 100%;
  }

  .d-flex-wrap {
    flex-wrap: wrap;
  }

  .field-content {
    flex: unset;
    width: 100%;
  }

  .site-btn {
    min-width: 200px;
    height: 48px;
    line-height: 1;
  }

  .add-photo-btn {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    line-height: 34px;
  }

  .ring-image-view a {
    width: 33.33%;
  }

  .ring-image-list {
    column-gap: 0;
  }

  .home-left-col::after,
  .home-right-col::after {
    display: none;
  }

  .home-left-col-2 {
    order: 6;
  }

  .home-left-col {
    border: 0;
    padding-right: 12px !important;
  }

  .home-right-col {
    border: 0;
    padding-left: 12px !important;
  }

  .main-home p {
    font-size: 16px;
  }

  .home-dark-btn,
  .home-light-btn {
    font-size: 18px;
    padding: 10px 24px;
  }

  .home-image-col {
    padding: 0 !important;
  }

  .home-page-container {
    padding: 0 !important;
    width: 100% !important;
  }

  .home-page-container .row {
    margin: 0 !important;
  }

  .home-content-col {
    width: calc(100% - 40px);
    margin: -45px auto 35px auto;
    z-index: 1;
    padding: 20px 15px;
    background-color: #fff;
  }

  .home-light-btn {
    background-color: #000;
    color: #fff;
  }

  .home-light-btn:hover {
    background-color: #fff;
    color: #000;
  }

  .home-footer {
    padding: 50px 20px;
  }

  .home-banner img {
    height: auto;
  }

  .home-right-img-col {
    order: -1;
  }

  .home-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100%;
  }

  .home-container .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .bottom-banner-content {
    position: unset;
    transform: none;
    padding: 50px 0;
  }

  .bottom-banner img {
    height: 310px;
    object-fit: cover;
    object-position: 64%;
  }
  

  .video-control {
    height: 70px;
    width: 70px;
    border-width: 4px;
  }
  
  .video-control svg {
    height: 50px;
    width: 50px;
  }
}

@media (max-width: 575px) {
  .overlay-step-image {
    right: 7%;
  }

  .process-step-day {
    right: 0;
  }

  .upload-view-thumb img {
    height: 160px;
  }

  .ring-image-list {
    margin: 0 -5px;
  }

  .ring-image-view a {
    width: 50%;
    padding: 0 5px;
    margin-bottom: 10px;
  }

  .ring-image-view img {
    height: 140px;
  }

  .request-view-section p {
    margin-bottom: 10px;
  }

  .request-view-title {
    font-size: 32px;
  }

  .request-view-title {
    width: 100%;
    margin-bottom: 15px !important;
  }

  .home-video {
    height: 280px;
  }
}

@media (max-width: 380px) {
  .bottom-banner img {
    height: 300px;
    object-position: 60%;
  }
}

@media (max-width: 370px) {
  .upload-view-thumb img {
    height: 140px;
  }

  .ring-image-view img {
    height: 120px;
  }

  .bottom-banner img {
    height: 280px;
    object-position: 60%;
  }
}